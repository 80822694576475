





















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    accomodationModule: {
      type: Boolean,
      required: false,
      default: false,
    },
    accomodationModuleData: {
      type: Boolean,
      required: false,
      default: false,
    },
    accomodationModuleHotels: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
